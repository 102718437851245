<template>
  <div class="flex h-screen">
    <div class="m-auto">
      <div class="pb-12 py-12">
        <p class="font-semibold text-headline-4 text-center">
          Algo Research
        </p>
      </div>
      <div class="mx-6 pb-6 py-3">
        <p class="font-headline-6 text-headline-6 p-3 mx-6">
          Sign in
        </p>
        <p class="font-light text-body-1 px-3 mx-6 text-gray-500">
          Please fill the below to continue
        </p>
      </div>
      <div class="mx-6 p-3">
        <InputTextBox
          class="px-0 py-2 mx-1"
          type="email"
          placeholder="Enter your email"
          @input="emailValue"
          @enter-pressed="loginOnEnterPressed()"
          :hide-errors="!emailError"
          :disabled="processing"
          warning="Please check again"
          warning-class="text-red-500"
        />
        <InputTextBox
          class="px-0 py-2 mx-1"
          type="password"
          @enter-pressed="loginOnEnterPressed()"
          placeholder="Enter your password"
          @input="passwordValue"
          :hide-errors="!passwordError"
          :disabled="processing"
          warning="Please check again"
          warning-class="text-red-500"
        />
      </div>
      <div class="mx-6 p-3">
        <div class="py-3 mx-1">
          <Loading :show="processing" />
          <ButtonSolidBox
            class="w-full h-full"
            v-show="!processing"
            color="primary"
            @click="login()"
            >Login</ButtonSolidBox
          >
          <p
            class="font-button text-button mx-auto text-center mt-3 text-gray-900 pt-8"
          >
            <router-link :to="'/forgot-password'">Forgot password?</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'

export default {
  components: {
    InputTextBox: InputTextBox,
    ButtonSolidBox: ButtonSolidBox,
    Loading: Loading
  },
  data: () => {
    return {
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      processing: false
    }
  },
  mounted () {
    if (localStorage.getItem('access_token') !== null) {
      this.$router.push({ name: 'route.dashboard.home' })
    }
  },
  methods: {
    loginOnEnterPressed () {
      this.login()
    },
    emailValue (val) {
      // console.log(val)
      this.email = val
    },
    passwordValue (val) {
      this.password = val
    },
    login () {
      if (this.email !== '' && this.password !== '') {
        this.emailError = false
        this.passwordError = false
        this.processing = true
        axios.post(process.env.VUE_APP_API_URL + '/v1/internal-user/auth', { email: this.email, password: this.password }, { withCredentials: true })
          .then(async (result) => {
            if (result.data.data.access_token !== undefined) {
              localStorage.setItem('access_token', result.data.data.access_token)
              this.$router.push({ name: 'route.dashboard.home' })
            } else {
              this.processing = false
              this.emailError = true
              this.passwordError = true
            }
            // this.processing = false
          })
          .catch((err) => {
            if (err.response) {
            // console.log(err.response)
            } else {
            // console.log(err)
            }
            this.processing = false
            this.emailError = true
            this.passwordError = true
          })
      } else {
        this.emailError = (this.email === '')
        // console.log('email error: ' + this.emailError)
        this.passwordError = (this.password === '')
        // console.log('password error: ' + this.emailError)
      }
    }
  }
}
</script>
